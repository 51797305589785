@import (reference) "./vars.less";

.hSpace {}
each(@spacings, {
	.hSpace--@{key} {
		padding-left: @value;
		padding-right: @value;
	}
});

.vSpace {}
each(@spacings, {
	.vSpace--@{key} {
		padding-top: @value;
		padding-bottom: @value;
	}
});

.vhSpace {}
each(@spacings, {
	.vhSpace--@{key} {
		padding: @value;
	}
});
